@import 'app.scss';
.card {
  box-sizing: border-box;
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: flex-start;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-gray-300);
  padding: spacing(10) spacing(8);
  width: 100%;
  height: 100%;
  aspect-ratio: 0.91; // design aspect ratio
  overflow: hidden;
  color: var(--color-white);
}

.title,
.subtitle,
.text,
.cta {
  z-index: 1;
}

.title {
  color: inherit;
  font-weight: 700;
}

.imageContainer {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;

  &::before {
    --gradient-size: 300px;

    position: absolute;
    inset: 0;
    opacity: 0.1;
    z-index: 1;
    background-color: var(--color-image-gradient-gray);
    content: '';
  }
}

.gradient {
  position: absolute;
  top: 0;
  opacity: 0.6;
  z-index: 1;
  background: var(--image-gradient);
  width: 100%;
  height: 60%;
}

.image {
  transition: transform var(--transition-duration-base)
    var(--transition-timing-base);
}

.cta {
  margin-top: auto;

  &:hover,
  &:focus-visible {
    & + .imageContainer .image {
      transform: scale(1.05);
    }
  }
}
