@import 'app.scss';
@keyframes rotate {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(180deg);
  }
}

.faqItem {
  border-bottom: 1px solid var(--color-border-light);
}

.header {
  width: 100%;
}

.text {
  margin-left: 0;
}

.accordionButton {
  display: flex;
  gap: spacing(1);
  align-items: center;
  justify-content: space-between;
  padding: spacing(6) 0;
  width: 100%;
  text-align: left;

  &:focus-visible {
    @include generic-focus;

    outline-offset: spacing(1);
  }

  &:hover,
  &:focus-visible {
    text-decoration: underline;

    .iconContainer {
      background-color: var(--color-bg-primary-brand-hover);
    }
  }

  &[aria-expanded='true'] {
    border-bottom: none;
  }
}

.iconContainer {
  border-radius: 50%;
  background-color: var(--color-bg-primary-brand);
  padding: spacing(1);
  color: var(--color-fg-on-primary-brand);
}

.svgIcon {
  width: 1.5rem;
  height: 1.5rem;
  animation: rotate var(--transition-duration-long) forwards;

  .accordionButton:hover & {
    color: var(--color-black-500);
  }
}

.answer {
  transition: var(--transition-base);
  transition-duration: var(--transition-duration-long);
  margin-left: 0;
  height: var(--item-height, 0);
  overflow: hidden;
}
