@import 'app.scss';
.list {
  column-count: 1;

  @include from-breakpoint(md) {
    column-count: 2;
  }

  @include from-breakpoint(lg) {
    column-count: 1;
  }

  @include from-breakpoint(xl) {
    column-count: 2;
  }
}
