@import 'app.scss';
.hero {
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
}

.columns {
  row-gap: var(--section-vertical-spacing);
}

.firstColumn {
  grid-column: 1/-1;

  @include from-breakpoint(lg) {
    grid-column: 1/9;

    &.narrow {
      grid-column: 1/7;
    }
  }
}

.secondColumn {
  grid-column: 1/-1;

  @include from-breakpoint(lg) {
    grid-column: 8/-1;
  }
}

.title {
  color: var(--color-headings-fg-on-default);
}

.children,
.ctaWrapper {
  margin-top: spacing(10);
}

.date {
  display: block;
  opacity: 0.5;
}

.text + .date {
  margin-top: spacing(7);
}

.mediaTransition {
  transition-duration: var(--transition-duration-long);
  transition-property: width, max-width, padding;
  transition-timing-function: var(--transition-timing-base);
  margin-right: auto;
  margin-left: auto;
  max-width: 120rem;

  .media {
    transition: border-radius var(--transition-base);
  }
}

.mediaAnimation {
  @include container-width;

  .mediaContainer {
    border-radius: var(--border-radius-medium);
  }
}

.mediaContainer {
  position: relative;
  margin-top: spacing(15);
  width: 100%;
  overflow: hidden;
  aspect-ratio: 375/514;

  @include from-breakpoint(md) {
    aspect-ratio: 1440/650;
  }

  @include from-breakpoint(lg) {
    margin-top: spacing(25);
  }
}

.ctaWrapper {
  display: flex;
  flex-wrap: wrap;
  gap: spacing(4);
}
