@import 'app.scss';
.container {
  row-gap: 0;
}

.punchline {
  grid-column: 1/-1;

  @include from-breakpoint(lg) {
    position: sticky;
    top: spacing(4);
    left: 0;
    grid-row: 1;
    grid-column: 1/3;
    margin-top: 0.3rem; // Specifc margin to align with the titles on the right
  }
}

.title {
  grid-column: 1/-1;
  margin-bottom: 0;

  .hasContent & {
    margin-bottom: spacing(10);
  }

  @include from-breakpoint(lg) {
    grid-column: 3/10;
  }
}

.content {
  grid-column: 1/-1;
  max-width: var(--max-text-width);

  @include from-breakpoint(lg) {
    grid-column: 3/10;
  }
}

.ctaButton {
  margin-top: spacing(10);
}

.fullWidth {
  .title,
  .content {
    @include from-breakpoint(lg) {
      grid-column: 1/-1;
    }
  }
}

.alternativeLayout {
  @include from-breakpoint(lg) {
    width: 100%;

    .title {
      grid-row: 1;
    }

    .punchline {
      grid-row: 2;
    }

    .content {
      grid-row: 2;
      grid-column: 1 / 8;
    }

    .punchline + .content {
      grid-column: 3 / 10;
    }
  }
}

.container.hasTextOrCta {
  .children {
    margin-top: spacing(10);
  }
}

.container.halfSlider {
  .content {
    pointer-events: all;
  }

  @include from-breakpoint(lg) {
    .title,
    .content {
      grid-column: 1 / 8;
    }

    .punchline + .content {
      grid-column: 3 / 8;
    }
  }
}

.container.textImageSection {
  @include from-breakpoint(lg) {
    .title,
    .content {
      grid-column: 3 / 7;
    }
  }
}

.container.fullSlider {
  .content {
    pointer-events: all;
  }

  @include from-breakpoint(lg) {
    .title {
      grid-column: 1 / 8;
    }

    .content {
      grid-column: 1 / 8;
    }
  }
}
