@import 'app.scss';
.mediaContainer {
  position: relative;
  background-color: var(--color-black);
  width: 100%;
  overflow: hidden;
  aspect-ratio: 16/9;
}

.media {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.thumbnailWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.consentBox {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  background-color: var(--color-black-transparent);
  padding: spacing(4);
  width: 100%;
  height: 100%;
}

.consentContent {
  position: relative;
  z-index: 1;
  border-radius: var(--border-radius-medium);
  box-shadow: var(--box-shadow-base);
  background-color: var(--color-white);
  padding: spacing(4);
  width: 100%;
  max-width: 25rem;
  text-align: center;

  .consentText {
    @include typography-heading-4;

    margin-bottom: spacing(4);
    line-height: 1.4;
    color: var(--color-text-base);
  }

  @include from-breakpoint(sm) {
    padding: spacing(4);

    .consentText {
      @include typography-heading-3;
    }
  }

  @include from-breakpoint(md) {
    padding: spacing(6);
  }
}
