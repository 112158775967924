@import 'app.scss';
.card {
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: var(--border-radius-base);
  background-color: var(--color-white);
  width: 100%;
  aspect-ratio: 0.75;
}

.imageContainer {
  width: calc(100% - #{spacing(8)});
}
