@import 'app.scss';
/**
* Pagination
*/

.pagination {
  display: flex;
  justify-content: center;
  margin-top: spacing(16);

  @include from-breakpoint(md) {
    margin-top: spacing(20);
  }
}
