@import 'app.scss';
.section {
  position: relative;
  overflow: hidden;

  &.sm {
    --slide-size: 220px;
  }

  &.md {
    --slide-size: 370px;
  }
}

.container {
  position: relative;

  // Fix scrollbar appearing underneath the content on iOS
  transform: translateZ(0);
  padding: 0 var(--horizontal-padding);
  overflow-x: auto;
  scroll-snap-type: x mandatory;

  &::before,
  &::after {
    display: none;
    position: absolute;
    right: var(--horizontal-padding);
    left: var(--horizontal-padding);
    opacity: 0.3;
    background: linear-gradient(
      to right,
      var(--color-black-transparent),
      transparent
    );
    height: 1px;
    content: '';
  }

  &::before {
    top: calc(-1 * var(--section-vertical-spacing));
  }

  &::after {
    bottom: calc(-1 * var(--section-vertical-spacing));
  }
}

.list {
  display: flex;
  flex-wrap: nowrap;
  gap: spacing(2);
  margin-top: spacing(10);

  @include from-breakpoint(md) {
    margin-top: 0;
  }

  @include from-breakpoint(lg) {
    display: none;
  }
}

.item {
  display: flex;
  flex-shrink: 0;
  z-index: 1;
  width: min(var(--slide-size), 80vw);
  scroll-margin-left: var(--horizontal-padding);
  scroll-snap-align: start;

  &.mobileItem {
    display: block;
  }
}

.swiperContainer {
  --gradient-size: 8px;

  display: none;
  position: relative;
  grid-row: 1;
  grid-column: 8 / span 5;
  z-index: 0;

  @include from-breakpoint(lg) {
    display: block;
  }
}

.swiper {
  overflow: visible;
}

.nav {
  transition: var(--transition-base);
  margin-top: spacing(6);
  border-radius: 50%;
  background-color: var(--color-bg-secondary-cta);
  padding: spacing(3); // spacing + icon size is as close to design as possible
  color: var(--color-fg-on-secondary-cta);

  &:hover,
  &:focus-visible {
    background-color: var(--color-bg-secondary-cta-hover);
    color: var(--color-fg-on-secondary-cta);
  }

  &:focus-visible {
    @include generic-focus;
  }

  &:disabled {
    opacity: 0.22;
    background-color: var(--color-gray-300);
    pointer-events: none;
  }
}

.navIcon {
  width: 2rem;
  height: 2rem;
}

.swiperSlide {
  transition: opacity 0.3s;
  width: var(--slide-size);
  height: auto;
}

.contentItem {
  position: sticky;
  left: 0;
  width: 100%;
}

/*  Variant 1: content slider */
.section.fullSlider {
  .contentItem {
    @include from-breakpoint(lg) {
      grid-column: 1 / -1;
      width: auto;
    }
  }

  .item {
    &:last-child {
      box-sizing: content-box;
      padding-right: var(--horizontal-padding);
    }
  }

  .list {
    margin-top: spacing(10);
  }

  .container {
    @include from-breakpoint(lg) {
      @include container;

      row-gap: var(--section-vertical-spacing);
      overflow-x: visible;
    }
  }

  .swiperContainer {
    grid-row: 2;
    grid-column: 1 / -1;
  }

  .navContainer {
    display: flex;
    justify-content: flex-end;
  }
}

/* Variant 2: Full slider (image slider & related pages) */
.section.halfSlider {
  &::before,
  &::after {
    position: absolute;
    right: var(--horizontal-padding);
    left: var(--horizontal-padding);
    opacity: 0.3;
    background: linear-gradient(
      to right,
      var(--color-black-transparent),
      transparent
    );
    height: 1px;
    content: '';
  }

  &::before {
    top: 0;
  }

  &::after {
    bottom: 0;
  }

  .container {
    display: flex;
    flex-flow: column nowrap;
    gap: spacing(2);

    @include from-breakpoint(md) {
      flex-direction: row;
    }

    @include from-breakpoint(lg) {
      @include container;

      overflow-x: visible;

      &::before,
      &::after {
        display: block;
      }
    }
  }

  .item {
    &:first-child {
      @include from-breakpoint(lg) {
        padding-right: 0;
        pointer-events: none;
      }
    }

    &.mobileItem {
      @include from-breakpoint(lg) {
        display: none;
      }
    }

    &.contentItem {
      height: min-content;
    }

    @include from-breakpoint(lg) {
      grid-row: 1;
      grid-column: 1 / -1;
      width: auto;

      &.contentItem {
        grid-column: 1 / -1;
      }
    }
  }

  .swiperContainer {
    &::before,
    &::after {
      position: absolute;
      top: 0;
      z-index: 2;
      height: 100%;
      content: '';
      pointer-events: none;
    }

    &::after {
      right: calc(100% + var(--gradient-size));
      background-color: var(--color-bg-default);
      width: 100vw;
    }

    &::before {
      right: 100%;
      background: linear-gradient(
        to right,
        var(--color-bg-default),
        transparent
      );
      width: var(--gradient-size);
    }
  }

  @include from-breakpoint(lg) {
    &::before,
    &::after {
      display: none;
    }
  }
}

.section.noBorderTop {
  .container {
    &::before {
      display: none;
    }
  }
}

.next {
  margin-left: spacing(1);
}
