@import 'app.scss';
.videoWrapper.hasContent {
  margin-top: spacing(20);
}

.video {
  grid-column: 1/-1;
  border-radius: var(--border-radius-medium);
  overflow: hidden;
}

.small {
  @include from-breakpoint(lg) {
    grid-column: 3/10;
  }
}
