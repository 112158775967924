@import 'app.scss';
.content {
  > :not(:last-child) {
    margin-bottom: spacing(10);
  }

  /**
  * Remove margin-bottom from last child of this container
  */
  > :last-child {
    margin-bottom: 0;
  }

  ul,
  ol {
    li {
      &:not(:last-of-type) {
        margin-bottom: spacing(4);
      }
    }
  }

  ul {
    padding-left: 0;

    li {
      position: relative;
      padding-left: spacing(8);
      list-style-type: none;

      &::before {
        position: absolute;
        left: 0;
        background-image: url('data:image/svg+xml, <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><polyline points="20 6 9 17 4 12"/></svg>');
        background-repeat: no-repeat;
        width: 1.25rem;
        height: 1.25rem;
        content: '';
      }
    }
  }
}
