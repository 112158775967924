@import 'app.scss';
.wrapper {
  --extra-padding-top: #{spacing(12)};

  padding-top: calc(var(--section-vertical-spacing) + var(--extra-padding-top));

  @include from-breakpoint(md) {
    --extra-padding-top: #{spacing(8)};
  }

  @include from-breakpoint(lg) {
    padding-top: 0;
  }
}

.container {
  --background-color: var(--color-bg-contact-section-primary);
  --text-color: var(--color-fg-on-contact-section-primary);

  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  border-radius: var(--border-radius-medium);
  background-color: var(--background-color);
  padding: spacing(14) spacing(6);
  text-align: center;
  color: var(--text-color);

  @include from-breakpoint(lg) {
    flex-direction: row;
    gap: spacing(15);
    padding: spacing(6) spacing(12);
    text-align: left;
  }
}

.infoContainer {
  display: flex;
  gap: spacing(14);
  align-items: center;
}

.figure {
  position: absolute;
  top: spacing(-20);
  left: 50%;
  transform: translateX(-50%);
  border-radius: 50%;
  width: 11rem;
  height: 11rem;
  overflow: hidden;

  @include from-breakpoint(lg) {
    position: relative;
    top: 0;
    left: 0;
    transform: none;
  }
}

.figure,
.cta {
  flex-shrink: 0;
}

.image {
  object-fit: cover;
}

.content {
  display: flex;
  flex-direction: column;
  gap: spacing(7);
  margin-top: spacing(20);
  margin-bottom: spacing(6);

  @include from-breakpoint(lg) {
    gap: spacing(2);
    margin-top: 0;
    margin-bottom: 0;
  }
}

.title {
  margin: 0;
  color: var(--color-white);
}

.container.accent {
  --background-color: var(--color-bg-contact-section-accent);
  --text-color: var(--color-fg-on-contact-section-accent);

  .title {
    color: var(--text-color);
  }
}
