@import 'app.scss';
.section {
  padding-top: var(--section-vertical-spacing);
  padding-bottom: var(--section-vertical-spacing);
}

.noPadding {
  padding: 0;
}

.smallPaddingTop {
  padding-top: spacing(6);
}
