@import 'app.scss';
.container {
  display: flex;
  flex-direction: column;
  gap: spacing(12);
  align-items: flex-start;
  padding-top: spacing(19);

  @include from-breakpoint(lg) {
    gap: spacing(14);
  }
}

.wrapper {
  width: 100%;
}
