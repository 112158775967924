@import 'app.scss';
.tag {
  --color: var(--color-bg-alternative);

  display: flex;
  border: 1px solid var(--color);
  border-radius: var(--border-radius-button);
  padding: spacing(2) spacing(4);
  text-decoration: none;
  color: var(--color);
  font-weight: 400;
  text-underline-offset: 0.125rem;
}

.secondary {
  --color: var(--color-fg-on-filter-cta);
  --color-hover: var(--color-fg-on-filter-cta-hover);
  --bg-color-hover: var(--color-bg-filter-cta-hover);

  &:hover,
  &:focus-visible {
    --color: var(--color-hover);

    background-color: var(--bg-color-hover);
  }
}
