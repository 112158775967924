@import 'app.scss';
.container {
  grid-column: 1/-1;

  @include from-breakpoint(lg) {
    grid-column: 3/8;
  }
}

.quote,
.name,
.role {
  color: var(--color-quote-text);
}

.quote {
  margin: 0;

  @include from-breakpoint(lg) {
    &::before {
      font-size: 8.5rem;
    }
  }
}

.quotationMark {
  margin-bottom: spacing(8);
  width: 2rem;
  height: auto;
  color: var(--color-quote-mark);

  @include from-breakpoint(md) {
    width: 3.5rem;
  }
}

.figure {
  position: relative;
  border-radius: 50%;
  width: 3.625rem;
  height: 3.625rem;
  overflow: hidden;
}

.image {
  object-fit: cover;
}

.line {
  margin-top: spacing(10);
  border: 1px solid var(--color-gray-900);
  width: 1.875rem;
}

.authorContainer {
  display: flex;
  gap: spacing(8);
  align-items: center;
  margin-top: spacing(10);
}

.name,
.role {
  margin: 0;
  line-height: 1.25;
  font-size: 1rem;
}

.name {
  font-weight: 600;
}

.role {
  font-weight: 400;
}
