@import 'app.scss';
.container {
  padding: 0 var(--horizontal-padding);

  @include from-breakpoint(lg) {
    @include container;

    row-gap: var(--section-vertical-spacing);
  }
}

.grid {
  grid-column: 1 / -1;
  grid-gap: spacing(2);

  @include from-breakpoint(lg) {
    margin-top: var(--section-vertical-spacing);
  }
}
