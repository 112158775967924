@import 'app.scss';
.container {
  align-items: flex-start;
}

.content {
  grid-column: 1/-1;

  @include from-breakpoint(lg) {
    grid-row: 1;
    padding-top: var(--section-vertical-spacing);
    padding-bottom: var(--section-vertical-spacing);
  }
}

.figure {
  grid-column: 1/-1;
  margin-top: spacing(10);
  border-radius: var(--border-radius-medium);
  overflow: hidden;

  @include from-breakpoint(lg) {
    position: sticky;
    top: var(--header-height);
    grid-row: 1;
    grid-column: 8/-1;
    margin-top: 0;
  }
}
