@import 'app.scss';
.card {
  display: flex;
  position: relative;
  gap: spacing(4);
  padding-bottom: spacing(4);

  &::after {
    display: block;
    position: absolute;
    right: 0;
    bottom: 0;
    left: 0;
    opacity: 0.3;
    background: linear-gradient(
      to right,
      var(--color-black-transparent),
      transparent
    );
    height: 1px;
    content: '';
  }

  @include from-breakpoint(sm) {
    flex-direction: column;
    gap: spacing(4);
  }

  @include from-breakpoint(md) {
    gap: spacing(8);
  }

  /* Show card animation only on desktop devices */
  @media (pointer: fine) {
    &:focus-within,
    &:hover {
      .image,
      .placeholder {
        transform: scale(1.05);
      }
    }
  }
}

.imageContainer {
  position: relative;
  border-radius: var(--border-radius-base);
  overflow: hidden;
  aspect-ratio: 97/159;

  @include until-breakpoint(md) {
    flex: 0 0 97px;
  }

  @include from-breakpoint(sm) {
    aspect-ratio: 404/155;
  }
}

.image,
.placeholder {
  transition: var(--transition-base);
}

.placeholder {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--color-gray-200);
  width: 100%;
  height: 100%;
  color: var(--color-border-base);
}

.logo {
  width: 70%;

  // Neccessary to style the accent color in the nimbl logo
  /* stylelint-disable-next-line selector-pseudo-class-no-unknown */
  :global(#accent) {
    fill: var(--color-gray-200);
  }

  @include from-breakpoint(sm) {
    width: 40%;
  }

  @include from-breakpoint(xl) {
    width: var(--header-logo-width);
  }
}

.content {
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: spacing(2) 0;

  @include from-breakpoint(sm) {
    padding: 0;
  }
}

.subtitle {
  opacity: 0.65;
}

.ctaContainer {
  margin-top: auto;
  padding-top: spacing(4);

  @include from-breakpoint(lg) {
    padding-top: spacing(8);
  }
}

.cta {
  color: var(--color-fg-on-text-cta);

  &:focus-visible,
  &:hover {
    color: var(--color-fg-on-text-cta-hover);
  }
}
