@import 'app.scss';
.card {
  box-sizing: border-box;
  position: relative;
  border-radius: var(--border-radius-medium);
  background-color: var(--color-bg-alternative);
  padding: spacing(35) spacing(7) spacing(7);
  width: 100%;
  height: 100%;
}

.dogEar {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  width: 20px;
  height: auto;

  &.out {
    right: calc(100% - 6px);
    color: var(--color-bg-alternative);

    [data-first='true'] & {
      display: none;
    }
  }

  &.in {
    right: -6px;
    color: var(--color-bg-default);

    [data-last='true'] & {
      display: none;
    }
  }
}

.title {
  color: var(--color-black);
}

.ctaButton {
  margin-top: spacing(6);
}
