@import 'app.scss';
.card {
  display: flex;
  position: relative;
  flex-direction: column-reverse;
  justify-content: space-between;
  border-radius: var(--border-radius-medium);
  min-height: 40.5rem;
  overflow: hidden;

  &:hover,
  &:focus-visible {
    .image {
      transform: scale(1.05);
    }
  }
}

.figure {
  position: absolute;
  inset: 0;
  margin-bottom: 0;
}

.image {
  transition: transform var(--transition-duration-long)
    var(--transition-timing-base);
}

.content {
  position: relative;
  padding: spacing(9) spacing(7);
  max-width: calc(29.375rem + #{spacing(7) * 2});

  @include from-breakpoint(md) {
    padding: spacing(10) spacing(14);
    max-width: calc(29.375rem + #{spacing(14) * 2});
  }
}

.title {
  margin-top: spacing(17);
  margin-bottom: 0;
  text-decoration: none;
  color: var(--color-white);
  font-weight: 600;

  @include from-breakpoint(md) {
    margin-top: spacing(10);
  }
}

.icon {
  transition: transform var(--transition-duration-base)
    var(--transition-timing-base);
}

.link {
  &:hover + .button,
  &:focus-visible + .button {
    background-color: var(--color-bg-quinary-cta-hover);

    .icon {
      transform: rotate(-45deg);
    }
  }
}

.button {
  display: flex;
  position: absolute;
  top: spacing(4);
  right: spacing(4);
  align-items: center;
  justify-content: center;
  z-index: 2;
  border-radius: 50%;
  background-color: var(--color-bg-quinary-cta);
  padding: 0;
  width: 2.6875rem;
  height: 2.6875rem;
  color: var(--color-fg-on-quinary-cta);
  pointer-events: none;

  @include from-breakpoint(md) {
    width: 3.375rem;
    height: 3.375rem;
  }
}
