@import 'app.scss';
.withoutControls {
  --controls: none;
}

.buttonContainer {
  display: flex;
  position: absolute;
  top: 0;
  left: 0;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
}

.button {
  @include umbrella-link;

  position: unset;
  border-radius: 50%;
  width: spacing(20);
  height: spacing(20);
}
